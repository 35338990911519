
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

 
* {
  font-family: 'Poppins', sans-serif;
}



body {
  margin: 0;
  

}

.bgtop {
  background-color: #6a2e23;
}

.bghead {
  background-color: #f8ce84;
}


.navcus {
  background-color: #D79922;
}

.App {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100vh;
}

.footer {
  margin-top: auto;
}

.jus {
  text-align: justify;
}

.avatar {
  border-radius: 50%;
  max-width: 120px;
}

.hhe {
  max-height: 220px;
}


.tickernewsbar {
  display: inline;
  list-style-type: none;
}

.tickernewsbar li {
  display: inline;
}

.tickercontent {
  padding-top: 13px;
}


.titlecolor {
  color:#6a2e23;
}